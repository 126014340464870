import { useMemo } from 'react';
import { type CountryCode } from '@stenarecycling/customer-portal-types';
import { useProfileV2 } from '../Profile';

export type SupportedCountries = {
  sweden?: boolean;
  denmark?: boolean;
  norway?: boolean;
  finland?: boolean;
  poland?: boolean;
};

export const getCountry = (dwKey: string) => {
  if (isFromSweden(dwKey)) {
    return { sweden: true };
  } else if (isFromDenmark(dwKey)) {
    return { denmark: true };
  } else if (isFromNorway(dwKey)) {
    return { norway: true };
  } else if (isFromFinland(dwKey)) {
    return { finland: true };
  } else if (isFromPoland(dwKey)) {
    return { poland: true };
  }
};

export const useCountries = () => {
  const { profile } = useProfileV2();

  const getAllCountries = () => {
    return {
      sweden: true,
      denmark: true,
      norway: true,
      finland: true,
      poland: true,
    } as SupportedCountries;
  };

  const supportedCountries: SupportedCountries = useMemo(() => {
    if (!profile) {
      return {};
    }
    const perms = profile.permissions;

    if (!perms?.businessPartners) {
      return {};
    }

    const countries = perms.businessPartners.reduce<SupportedCountries>((acc, bp) => {
      const country = getCountry(bp.id);

      if (!country) {
        return acc;
      }

      return { ...acc, ...country };
    }, {});

    return Object.keys(countries).length > 0 ? countries : getAllCountries();
  }, [profile]);

  const supportedCountryCodes = useMemo(() => {
    const countryCodes: CountryCode[] = [];

    if (supportedCountries.sweden) {
      countryCodes.push('SE');
    }
    if (supportedCountries.denmark) {
      countryCodes.push('DK');
    }
    if (supportedCountries.norway) {
      countryCodes.push('NO');
    }
    if (supportedCountries.finland) {
      countryCodes.push('FI');
    }
    if (supportedCountries.poland) {
      countryCodes.push('PL');
    }

    return countryCodes;
  }, [supportedCountries]);

  return { supportedCountries, supportedCountryCodes };
};

export const isFromSweden = (dwKey: string) => dwKey.startsWith('104') || dwKey.startsWith('900');
export const isFromNorway = (dwKey: string) => dwKey.startsWith('250') || dwKey.startsWith('901');
export const isFromDenmark = (dwKey: string) => dwKey.startsWith('555') || dwKey.startsWith('902');
export const isFromFinland = (dwKey: string) => dwKey.startsWith('505');
export const isFromPoland = (dwKey: string) => dwKey.startsWith('105');
export const isFromConfidential = (dwKey: string) =>
  dwKey.startsWith('900') ||
  dwKey.startsWith('901') ||
  dwKey.startsWith('902') ||
  dwKey.startsWith('903');
